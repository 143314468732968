import React from 'react'
import Menu from '../component/Menu';
import HeaderContact from '../component/HeaderContact';
import ContactForm from '../component/ContactForm';
import Footer from '../component/Footer';


const Contact = ({setBlank}) => {
  return (
    <div>
       <Menu />
       <HeaderContact />
       <ContactForm />
       <Footer setBlank={setBlank} class="contact" referance="footer.contact" />  
    
    </div>
  )
}

export default Contact
