import React from 'react'
import Container from 'react-bootstrap/Container';
import headleft from '../images/head-left.webp';
import headright from '../images/head-right.webp';
import './headerpp.css'
const Headerpp = () => {
  return (
    <header className='header-pp'  >
    {/* header Privacy Policy starts */}
    <Container fluid className='hero px-5'>
      <div className='hero-content'>

        <div className='hero-text'>
          <div className='text-slider pp-mobile-view'>
            <img src={headleft} className='capsuleleft' alt='left-capsule' />
            <div className='text-slide-box'><h3 className="text-slide">Privacy Policy </h3></div>
            <img src={headright} className='capsuleright' alt='right-capsule' />
          </div>
        </div>
      </div>

    </Container>
{ /* header Privacy Policy ends */}
  </header>
  )
}

export default Headerpp
