import React from 'react'
import Menu from '../component/Menu';
import MembersComp from '../component/MembersComp';
import HeaderMember from '../component/HeaderMember';
import Footer from '../component/Footer';

const Members = ({setBlank}) => {
  return (
    <>
       <Menu />
       <HeaderMember />
       <MembersComp setBlank={setBlank} />
       <Footer setBlank={setBlank} class="member" referance="footer.member" />  
     </>
  )
}

export default Members
