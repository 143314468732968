import * as Yup from "yup";
// const phoneRegExp = '/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/';

export const signUpSchema = Yup.object({
  name: Yup.string().min(2).max(50).required("Please enter your Name").matches(/^[aA-zZ'.,/-\s]+$/, "Only alphabets are allowed for this field "),
  company: Yup.string().min(2).max(50).required("Please enter your Company"),
  // company: Yup.string().min(2).max(50).required("Please enter your Company").matches(/^[aA-zZ'.,/-\s]+$/, "Only alphabets are allowed for this field "),
  designation: Yup.string().min(2).max(25).required("Please enter your Designation").matches(/^[aA-zZ'.,/-\s]+$/, "Only alphabets are allowed for this field "),
  country: Yup.string().required("Please select your Country"),
  email: Yup.string().email().min(2).required("Please enter your Email").matches(/^[\w-.]+@([\w-]+\.)+[\w-]{2,10}$/, "Email must be a valid email "),
  // phone: Yup.string().min(10).max(13).required("Please enter your Phone Number").matches(/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/, "Phone number is not valid "),
  phone: Yup.string().required("Please enter your Phone Number")
  .matches(/^([ +0-9]{1,4}|[ -0-9 ]{1,3})?[ 0-9 ]{10,14}$/, "Phone number is not valid ").min(10).max(16)
  .matches(/^[0-9'+/-\s]+$/, "Only Numbers are allowed for this field "),
  // message: Yup.string().min(4).required("Please enter Message"), 
});


// .test('test-name','Validation failure message',(value) => {
//   // your logic
//   console.log(value);
//   value = value.replace(/^[a-zA-z]$/g,'');
//   value="test";
//   return value;
// } )

// const phonevalidation = (value) => {
//   value = value.replace(/^[a-zA-z]$/g,'');
// }

// RegExp("^\\+[1-9]{1}[0-9]{0,2}-[2-9]{1}[0-9]{2}-[2-9]{1}[0-9]{2}-[0-9]{4}$");
