import React from 'react'
import './singleheader.css'
import { Container } from 'react-bootstrap'
//import Menu from './Menu'
//import img1 from '../images/pubclubbenefit.webp'
import headleft from './../images/head-left.webp';
import headright from './../images/head-right.webp';

const Singleheader = (props) => {
  return (
    <>
      <section className='pubclub-benefit pb-5'>
        {/* Single start */}
        <Container fluid className="header-container container">
          <div className="d-flex header-img">
            <div className="left-img"><img src={`${headleft}`} alt='left-capsule' /></div>
            <div className='thumb-img'><img src={props.data.thumbnail} alt='Pubclub Benefit' /></div>
            <div className="right-img"><img src={`${headright}`} alt='right-capsule' /></div>
          </div>
          <h1 className='benefit-heading' dangerouslySetInnerHTML={{__html:props.data.post_title}} ></h1> 
          <p className='pubclub-benefit-para'>by {props.data.auther_name}   -    {props.data.post_date}</p>

        </Container>
         {/* Single headder ends */}
      </section>
    </>
  )
}

export default Singleheader