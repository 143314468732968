import React from 'react'
import { Container, Row } from 'react-bootstrap'
import './aboutheader.css'

import Lottie from "lottie-react";
import aboutpubclub from "../lottie-files/About Pubclub/data.json";


const Aboutheader = () => {
  return (
    <>

      {/* About header section start */}
      <header className='about-header'>
 
        <Container fluid className='about-subheader'>
          <Row>

          <Lottie className='headergif' animationData={aboutpubclub} loop={true} />
       
            <p className='about-para aboutdesktopviewpara'>PubClub is bringing the next gen of publishers and content creators,<br/>
            empowering them to be at the forefront in emerging markets.
            </p>
            <p className='about-para aboutmobileviewpara'>PubClub is bringing the next gen of publishers <br/> and content creators,
            empowering them to be <br/> at the forefront in emerging markets.
            </p>
          </Row>
        </Container>
      </header>
      {/* About header section ends */}

    </>
  )
}

export default Aboutheader