import React from 'react'
import Menu from '../component/Menu'
import HeaderInsights from '../component/HeaderInsights'
import Insightbody from '../component/Insightbody'
import Footer from '../component/Footer'
const Insights = ({setBlank}) => {
  return (
    <>
       <Menu />
       <HeaderInsights />
       <Insightbody setBlank={setBlank} />
       <Footer setBlank={setBlank} class="insights" referance="footer.insights" />  
    </>
  )
}

export default Insights