import React from 'react'
import './headermember.css';
import Container from 'react-bootstrap/Container';

import bg from '../images/headermember.webp';
import bgmb from '../images/headermember-mb.webp';

import Lottie from "lottie-react";
import members from "../lottie-files/Members/data.json";


const HeaderMember = () => {
  return (
    <header className='header-member' > 
      {/* header Member starts */}
      <div className='bg'>
          <img className='desktop' src={bg} alt='' />
          <img className='mobile' src={bgmb} alt='' />
      </div>

    <Container fluid className='hero px-5'>
        <div className='hero-content'>
            
            <div className='hero-text'>
                <div className='gif-image mb-4'> 
                  <Lottie className='headermembergif' animationData={members} loop={true} />
                  </div>
                <p className='memdesktopview-para'>Inspiring publishers and content creators to lead<br /> the Ad Tech landscape with nuanced approaches</p>
                <p className='memmobview-para'>Inspiring publishers and content creators to <br /> lead the Ad Tech landscape with nuanced approaches</p>
            </div>
        </div>
    
     </Container>
     {/* header Member ends */}
    </header>
  )
}

export default HeaderMember