import React, {useEffect} from 'react'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import Menu from '../component/Menu';
import AdvisoryHeader1 from '../component/AdvisoryHeader1'
import Advisorysection from '../component/Advisorysection'
import Footer from '../component/Footer'

gsap.registerPlugin(ScrollTrigger);


const AdvisoryCouncil = ({setBlank}) => {


  useEffect(() => {
    
    ScrollTrigger.refresh();

  }, []);
  ScrollTrigger.refresh();


  return (
    <>
      <Menu /> 
      <AdvisoryHeader1/>
      <Advisorysection  setBlank={setBlank} />
      <Footer setBlank={setBlank} class="advisory" referance="footer.advisory" />  
    </>
  )
}

export default AdvisoryCouncil