import React from 'react'
import { Container,Row} from 'react-bootstrap'
import './tandcbody.css'

const Tandcbody = () => {
  return (
    <section className='tandc-section mt-lg-5 pt-lg-5 mt-3 pt-3'>
  {/* Terms and Condition body starts */}
    <Container className='mb-5 pb-5'>
        <Row>
            <div className='tandc-section-para' >

                <p>Access to and use of PubClub (pubclub.com) website (the "Site") is provided subject to the
                following Website Use Policy (the "Policy'), and the Site user, including, without limitation, any
                individual visiting or exploring the Site, (individually and collectively "you") waives any and all
                rights or claims against PubClub.</p>

                <p className='uppercase'>
                USE OF THIS WEBSITE CONSTITUTES ACCEPTANCE OF THE FOLLOWING TERMS AND CONDITIONS:
                </p>

                <p>
                The Policy is not exhaustive and PubClub reserves the right to add, delete, or modify any
                provision of the Policy at any time without notice to yourself, effective upon the posting of the
                modified Policy to (www.pubclub). Please check the Site periodically for changes to the Policy.
                By using PubClub services and/or using this Site after changes have been made to the Policy,
                you agree to the changes. Except when expressly agreed to the contrary in writing by an
                authorized representative of PubClub, this Policy supersedes any other agreement with
                PubClub, whether written, oral, by conduct, or otherwise.
                </p>

                <h3>1. USE OF MATERIALS</h3>
                <p>All materials, including, without limitation, information, artwork, images, pictures, text, video,
                audio, programs, and media files (the "Materials") contained on the Site are protected by
                copyright laws. You may access and use the Materials for personal and non-commercial
                purposes only. Any modification or use of the Materials for any other purpose without PubClub
                express prior written consent is strictly prohibited. Except as expressly provided herein, any
                reproduction, republishing, posting, transmission, or distribution of any Material on the Site is
                strictly prohibited.</p>


                <h3>2. COPYRIGHT AND TRADEMARK</h3>

                <p>You must not, without PubClub express written consent, use any PubClub trademark, service
                mark, or copyrighted material. Copyright © PubClub. Media (including, without limitation,
                PubClub parent companies and subsidiaries) and/or its licensors, all rights reserved. All
                trademarks and logos contained in any and all materials and information are the property of
                their respective owners. Use of such trademarks and logos is strictly prohibited without prior
                permission for use from the rightful owners.</p>

                <h3>3. PRINTING OF MATERIALS</h3>
                
                <p>Notwithstanding the foregoing, PubClub authorizes the printing of Materials found on the Site for
                personal or educational purposes only, provided that any and all copyright notices originally
                included with the Materials are included in any and all copies.</p>
                
                <h3>4. LINKS AND THIRD-PARTY CONTENT</h3>
                
                <p>PubClub may itself, and working with a number of Third Parties, provide links to other websites
                or resources from the Site. PubClub may also provide the opportunity for Third Parties to
                submit, post, transmit or otherwise make available information, data, text, photographs, sound,
                graphics, video, messages, reviews or other materials ("Third Party Content") through the Site.
                Because PubClub has no control over the content and performance of any Third-Party websites,
                resources or such Third-Party content. PubClub makes no guarantees about the accuracy,
                integrity, content, availability or quality of the information or services provided by such websites,
                resources or Third-Party Content, and PubClub assumes no responsibility for unintended,
                objectionable, inaccurate, misleading or unlawful content that may be accessed. Under no
                circumstances will PubClub be liable in any way for any Third-Party content or services provided
                by any Third-Party referenced on or linked from the Site. PubClub is providing these links to you
                only as a convenience, and the inclusion of any link does not imply affiliation, endorsement or
                adoption by PubClub of the Site or any information contained therein. When leaving the Site,
                you should be aware that PubClub policy no longer governs, and therefore you should review
                the applicable terms and policies, including privacy and data-gathering practices, of that
                website.</p>

                <h3>5. CUSTOMER RESPONSIBILITY</h3>
                
                <p>You are required to use the Site responsibly. PubClub reserves the right to suspend and/or
                cancel access to any user who uses the Site in such a way that adversely affects other
                (www.pubclub) users. While PubClub may monitor its service electronically to determine that its
                facilities are operating satisfactorily, as a general practice, PubClub does not monitor its users'
                activities to determine whether they are in compliance with the Policy. However, when PubClub
                becomes aware of any violation of the Policy or other user agreements, PuBClub may take any
                action to stop or correct such violation, including, but not limited to, denying access to the Site.
                In addition, PubClub may take action against you or a customer of yours because of the
                activities of you or such customers.</p>
                
                <h3>6. PROHIBITED USES</h3>

                 <ul className='list-unstyled ps-5'>
                    <li>
                        <h3>A. Use of the Site</h3>
                        <p>You agree not to use the Site for any illegal or unauthorized activity. You agree that you
                        will not use any device, software, or other technology to interfere or attempt to interfere
                        with the proper working of the Site. You agree not to crawl, spider, scrape, or otherwise
                        deploy automated agents or other technology to collect, harvest, mine, or otherwise
                        interact with this site.</p>
                     </li> 
                     <li>  
                        <h3>B. Compliance with Intellectual Property Laws</h3>
                        <p>When accessing the Site, you agree to obey the law and respect the intellectual property
                        rights of others. Your use of the Site is at all times governed by and subject to laws
                        regarding copyright ownership and use of intellectual property. You agree to abide by
                        laws regarding copyright ownership and use of intellectual property</p>
                    </li>
                </ul>


                <h3>7. ACTIONS TAKEN BY PubClub</h3>
                <p>PubClub will be the sole arbiter as to what constitutes a violation of the Policy. PubClub
                reserves the right to remove any Third Party Content without prior notice and to refuse access
                to anyone at any time. When AndBeyond.Media becomes aware of an alleged violation of its
                Policy, PubClub will initiate an investigation. During the investigation, PubClub may restrict your
                access to the Site in order to prevent further potentially unauthorized activity. Depending on the
                severity of the violation, PubClub may, at its sole discretion, restrict, suspend, or terminate the
                Site and/or pursue other civil remedies. If such a violation is a criminal offense, PubClub will
                notify the appropriate law enforcement authorities of such a violation. An unlisted activity may
                also be a violation of the Policy if it is illegal, irresponsible, or constitutes disruptive use of the
                Internet. If you are found to be in violation of the Policy, you are responsible, without limitations,
                for the cost of labor to rectify any damage done to the operation of the Site and business
                operations supported by the Site, and to respond to complaints incurred by PubClub.</p>
                
                <h3>8. DISCLAIMER</h3>
                
                <p>he services, information, and data (collectively the "Information") made available on the Site
                are provided on an "as is", "as available" basis without warranties of any kind, either express or
                implied, including, but not limited to, warranties of merchantability, fitness for a particular
                purpose, non-infringement, or regarding the quality, sequence, accuracy, timeliness, reliability,
                or validity of the Information. PubClub expressly disclaims any representation or warranty that
                the Site will be error-free, secure or uninterrupted. No oral advice or written information given by
                (www.pubclub), its employees, licensors or the like, will create a warranty; nor may you rely on
                any such information or advice. PubClub and its partners and suppliers will not be liable for any
                cost or damage arising either directly or indirectly from any transaction or use of the service.
                You understand, acknowledge, and agree that you are to use PubClub services and the site,
                and all aspects thereof, at your sole risk. PubClub further expressly disclaims any and all
                warranties that the site or the server that makes the site available is free of viruses or other
                harmful components. PubClub will have absolutely no liability for damage to your computer, any
                hardware, data, information, materials, and business resulting from your access to the site
                and/or the information, or the lack of information, available on the site. Furthermore, PubClub
                will have no liability for:</p> 

                <ul>
                    <li>
                    Any and all losses or injuries caused, in whole or in part, by andbeyond.media.com's
                    actions, omissions, or negligence, or for contingencies beyond (www.pubclub) control
                    in procuring, compiling, or delivering the information;</li>
                    <li>Any and all errors, omissions, or inaccuracies in the information regardless of how
                    caused, or delays or interruptions in delivery of the information; or</li>
                    <li> Any decision made or action taken or not taken, in reliance upon the information
                    furnished on the website.
                    </li>
                </ul>      

                <p>
                or the purposes of this section, (www.publcub) includes, without limitation, PubClub divisions,
                subsidiaries, successors, parent companies, and their (including netnation's) executives,
                directors, officers, managers, employees, consultants, contractors, agents, affiliates, third-party
                providers, merchants, licensors or the like.
                </p>

                <p>You agree that certain services may be provided by third-party providers of PubClub and that
                your use of such services may be subject to further agreements, policies, terms, and conditions
                in addition to those contained or referenced in this Agreement. Any mention of non-PubClub
                services by (www.pubclub), its employees, executives, directors, officers, managers,
                consultants, agents, parent companies, and/or subsidiaries, or any third-party entity related to
                PubClub is for information purposes only and does not constitute an endorsement or
                recommendation by PubClub. PubClub disclaims any and all liabilities for any representation or
                warranty made by the vendors of such non-PubClub products or services.</p>

                <h3>10. LIMITATION OF LIABILITY</h3>
                <p>To the maximum extent permitted by applicable law, in no event, and under no theory of law or
                equity, will PubClub (including, without limitation, PubClub executives, directors, officers,
                attorneys, managers, employees, consultants, contractors, agents, parent companies,
                subsidiaries, affiliates, third-party providers, merchants, licensors, or the like) or anyone else
                involved in creating, producing, or distributing PubClub services and/or the site, be liable for any
                business or personal loss, revenues decrease, expenses increase, or any other loss or damage
                whatsoever, or for any consequential, special, incidental, punitive or indirect damages of any
                kind arising out of any use of, or any inability to use or access the site even if PubClub has been
                advised of the possibility of such damages. your sole remedy is to discontinue the use of the
                Site.</p>

                <h3>11. PRIVACY</h3>
                <p>PubClub is concerned with the privacy of online communications and websites. In general, the
                Internet is neither more nor less secure than other means of communication, including mail,
                facsimile, and voice telephone service, all of which can be intercepted and otherwise
                compromised. As a matter of prudence, however, PubClub urges its customers to assume that
                all of their online communications are insecure. PubClub cannot take any responsibility for the
                security of information transmitted over PubClub facilities. All information gathered by us from
                you in connection with your use of this site is subject to the provisions of our Privacy Statement.
                We do not control, nor are we responsible for, the privacy practices of those sites to which we
                link and, therefore, you agree to read the privacy policies of those sites.</p>

                <h3>12. INDEMNITY</h3>
                <p>You agree to protect, defend, hold harmless, and indemnify PubClub, any third-party entity
                related to PubClub (including, without limitation, third-party vendors), and PubClub executives,
                directors, officers, managers, employees, consultants, agents, parent companies, and
                subsidiaries from and against any and all liabilities, losses, costs, judgments, damages, claims,
                or causes of actions, including, without limitation, any and all legal fees and expenses arising
                out of or resulting from your use of the Site.</p>

                <h3>13. GENERAL INFORMATION</h3>
                <h4>A. Jurisdiction and Severability</h4>
                <p>The Policy, and any other agreement for PubClub services, will be governed by and construed
                in accordance with the laws of courts of Dubai, UAE, without reference to its conflicts of laws
                principles. Any litigation or arbitration between a customer and PubClub will take place in Dubai,
                UAE, and the customer will consent to personal jurisdiction and venue in that jurisdiction. If any
                provision or portion of the Policy or other PubClub agreement is found by a court of competent
                jurisdiction to be unenforceable for any reason, the remainder of the Policy or the agreement will
                continue in full force and effect.</p>

                <h4>B. No Waiver</h4>
                <p>Any failure to exercise or enforce any right or provision of the Policy will not constitute a waiver
                of such right or provision.</p>

                <h4>C. Construction and Interpretation</h4>
                <p>Wherever in this Policy the masculine, feminine, or neuter gender is used, it will be construed as
                including all genders, and wherever the singular is used, it will be deemed to include the plural
                and vice versa, where the context so requires. The division of the Policy into
                sections/paragraphs, and the insertion of headings/captions, are for convenience of reference
                only and will not affect the construction or interpretation of the Policy. Any rule of construction to
                the effect that any ambiguity is to be resolved against the drafting party will not be applicable in
                the construction or interpretation of the Policy.</p>

                <h4>D. Complete Agreement and Exclusivity</h4>
                <p>The Policy, and/or any other specific agreement for PubClub services, constitutes the complete
                understanding and agreement between yourself and PubClub with respect to the subject matter
                hereof and governs your use of the Site. You also may be subject to additional terms and
                conditions that may apply when you use affiliate services, Third Party Content or third-party
                software. Except when expressly agreed to the contrary in signed writing by an authorized
                representative of PubClub, the Policy supersedes any other written (including
                digitized/computerized) agreement, oral agreement, and/or agreement by conduct. This Policy,
                and/or any other specific agreement for PubClub services is between PubClub and its
                customers only and will not confer any rights in any third party except as otherwise expressly
                provided by PubClub</p>
            </div>
        </Row>
    </Container>
     {/* Terms and Condition body ends */}
</section>
)
}

export default Tandcbody
