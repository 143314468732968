import React from 'react'
import './advisoryheader1.css'
import { Container, Row } from 'react-bootstrap'
import bg from '../images/advisorycouncil1.webp';
import bgmb from '../images/advisorycouncilbgmobile.webp';

const AdvisoryHeader1 = () => {
  return (
    <>
      {/* Advisory Coouncil page Header Start */}
      <section className='advisory-council1'>
      <div className='bg'>
          <img className='desktop' src={bg} alt='' />
          <img className='mobile' src={bgmb} alt='' />
        </div>

        <Container fluid className='advisory'>
          <Row className='advisorymobile-view'>
            <h1 className='advisory-heading'>ADVISORY COUNCIL</h1>
            <p className='council-para advisorydesktop-view-para'>The best in the business. Thinkers and subject matter experts of<br/>PubClub paving the way for next-gen of publishers and content creators.</p>
            <p className='council-para advisorymobile-view-para'>The best in the business. Thinkers and industry experts paving <br/>the way for next-gen of publishers and content creators.</p>
          </Row>
        </Container>
      </section>
      {/* Advisory Coouncil page Header Start */}
      </>
  )
}

export default AdvisoryHeader1