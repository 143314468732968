import React from 'react'
import Container from 'react-bootstrap/Container';
import headleft from '../images/head-left.webp';
import headright from '../images/head-right.webp';

import './headertandc.css'


const Headertandc = () => {
  return (
    <header className='header-tandc'  >
      {/* header Terms and Condition starts */}

    <Container fluid className='hero px-5'>
    
      <div className='hero-content'>
        <div className='hero-text'>
          <div className='text-slider headertandc-mobile-view'>
            <img src={headleft} className='capsuleleft' alt='left-capsule' />
            <div className='text-slide-box'><h3 className="text-slide">Terms And Conditions </h3></div>
            <img src={headright} className='capsuleright' alt='right-capsule' />
          </div>
        </div>
      </div>
    </Container>
    {/* header Terms and Condition ends */}
  </header> 
   )
}

export default Headertandc
