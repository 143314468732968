import React from 'react'
import './headermembeni.css';
import Container from 'react-bootstrap/Container';
// import headleft from '../images/head-left.webp';
// import headright from '../images/head-right.webp';

// import memberbenegif from '../images/gif/member-benefits-gif.gif';

import Lottie from "lottie-react";
import memberbenefi from "../lottie-files/Member Benefits/data.json";

const HeaderMembeni = () => {
  return (
    <>
    {/* header insights starts */}
    <header className='header-membeni'  >
      <Container fluid className='hero px-5'>
        <div className='hero-content'>
          <div className='hero-text'>           
            <div className='gif-image pb-3'>  
            <Lottie className='headergif' animationData={memberbenefi} loop={true} />
            </div>
            <p className='member-headmobile-view-para'></p>
          </div>
        </div>
      </Container>
    </header>
    {/* header insights ends */}
    </>

  )
}

export default HeaderMembeni