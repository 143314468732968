import React from 'react'
import './exclusive.css'
import { Row, Col, Container } from 'react-bootstrap';
import down from '../images/down.webp';

import img1 from '../images/numone.webp';
import img2 from '../images/numtwo.webp';
import img3 from '../images/numthree.webp';
import img4 from '../images/numfour.webp';
import img5 from '../images/numfive.webp';
import { NavLink } from "react-router-dom";

const Exclusive = (props) => {
    
  const scrollToTop = () => {
    props.setBlank(true);
    setTimeout(() => {
    window.scrollTo(0, 0);
    }, 100);
    setTimeout(() => {
      props.setBlank(false);            
    }, 700);
  }

    const data = props.data;
    return (
        <>
            <section className='exclusivebenefit'>
                {/* EXCLUSIVE CLUB BENEFITS Container starts here */}
                <Container fluid className='px-5p mt-5 exclusive-desktop-view'>
                    <h3 className="heading">EXCLUSIVE CLUB BENEFITS</h3>
                    <Row className='mt-lg-5 pt-lg-5 mt-3 pt-3'>
                        <Col className='col-md-6'>
                            <Container className='exclusiveclub'>
                                <Row className='fixrow'>
                                    <Col className='col-md-3'>
                                        <img src={img1} alt='' />
                                    </Col>
                                    <Col className='col-md-9 para-margin'>
                                        <div className='para-box'>
                                            <p className='numheading'>Opportunity to establish</p>
                                            <p className='exclusive-para'>a strong personal reputation through PR initiatives.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col className='col-md-6'>
                            <Container className='exclusiveclub'>
                                <Row className='fixrow'>
                                    <Col className='col-md-3'>
                                        <img src={img2} alt='' />
                                    </Col>
                                    <Col className='col-md-9 para-margin'>
                                        <div className='para-box'>
                                            <p className='numheading'>Publish opinion articles </p>
                                            <p className='exclusive-para'>under your byline for regional publications. </p>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Container className='exclusiveclub'>
                                <Row className='fixrow'>
                                    <Col className='col-md-3'>
                                        <img src={img3} alt='' />
                                    </Col>
                                    <Col className='col-md-9 para-margin'>
                                        <div className='para-box'>
                                            <p className='numheading'>Showcase insights</p>
                                            <p className='exclusive-para'>about the ad tech market through video series.</p>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col>
                            <Container className='exclusiveclub'>
                                <Row className='fixrow'>
                                    <Col className='col-md-3'>
                                        <img src={img4} alt='' />
                                    </Col>
                                    <Col className='col-md-9 para-margin'>
                                        <div className='para-box'>
                                            <p className='numheading'>Connect with industry associations</p>
                                            <p className='exclusive-para'>over webinars for insightful conversations.</p>
                                         </div>
                                   </Col>
                                </Row>
                            </Container>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Container className='exclusiveclub'>
                                <Row className='fixrow'>
                                    <Col className='col-md-3'>
                                        <img src={img5} alt='' />
                                    </Col>
                                    <Col className='col-md-9 para-margin'>
                                    <div className='para-box'>
                                        <p className='numheading'>Grow public recognition</p>
                                        <p className='exclusive-para'>by participating in prestigious award ceremonies</p>
                                    </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                        <Col>
                            {/* <Container className='exclusiveclub'>
                                <Row>
                                    <Col className='col-md-3'>
                                        <img src={img1} alt='' />
                                    </Col>
                                    <Col className='col-md-9 para-margin'>
                                        <p className='numheading'>Opportunity to establish</p>
                                        <p className='exclusive-para'>a strong personal reputation through PR initiatives.</p>
                                    </Col>
                                </Row>
                            </Container> */}

                        </Col>
                    </Row>
                </Container>
                <Container className='exclusive-mobile-view mt-5 pt-4'>
                <h3 className="heading mobile-view-heading">EXCLUSIVE <br /> CLUB BENEFITS</h3>
                    {data.map((d) => {
                        return (
                                <Row key={d.id}>
                                    <Col className='col-md-1 imgmargin'>
                                        <img src={d.numimg} alt=' '/>
                                    </Col>
                                    <Col className='col-md-11 pt-4 mt-3 margindiv'>
                                        <h3 className='numheading'>{d.numheading}</h3>
                                        <p className='numpara'>{d.numcontent}</p>
                                    </Col>
                                </Row>
                        )
                    })}
                </Container>
                 {/* EXCLUSIVE CLUB BENEFITS Container Ends here */}
          
                {/* want to know more mobile section starts */}
                <Container className='mobileview-footer mt-4 pt-3'>
                    <Row>
                    <Col className='mx-auto text-center mt-5'>
                        <div className='footer-heading'>
                        <h3>WANT  T<span><img src={down} alt='' style={{ transform: "rotate(180deg) translateY(50%)" }}/></span></h3>
                        <h3>KN<span><img src={down} alt=''style={{ transform: "rotate(180deg) translateY(50%)" }}/></span>W M<span><img  src={down} alt='' /></span>RE?</h3>
                        </div>

                        <NavLink to='/applytojoin' onClick={scrollToTop}> <button className="contact-btn mt-5">CONTACT US</button></NavLink>
                    </Col>
                    </Row>
                </Container>
                {/* want to know more mobile section ends */}
            </section>

           
        </>
    )
}

export default Exclusive