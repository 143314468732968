import React, { useState, useEffect } from 'react'
import { Row, Col, Container} from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import arrowlink from '../images/arrow-link-hbg.webp'
import blankthumbnail from '../images/blank-thumbnail.webp'
import './homeblog.css';

gsap.registerPlugin(ScrollTrigger);

const Homeblog = ({setBlank}) => {

  const scrollToTop = () => {
    setBlank(true);
    setTimeout(() => {
      ScrollTrigger.refresh();
      window.scrollTo(0, 0);
    }, 100);
    setTimeout(() => {
        setBlank(false);            
    }, 700);
  }

    const [relatedPost, setRelatedPost] = useState([]);

    useEffect(() => {
        fetch("https://blogs.pubclub.io/wp-json/insights/recent-post/posts", { method: "GET"})
        .then(response => response.json())
        .then(data => {
            console.log("related post: "+data)
            setRelatedPost(data)   
        });
    }, []);

    ScrollTrigger.refresh();
    return (

      <>
      {/* blog section start */}
    <section className='homeblog-section' >
    <Container fluid className='px-5p'>
      <Row className=''>
        <Col className='col-md-12'>
          <h3 className="heading ">WHAT  WE’VE  BEEN  UPTO</h3>
        </Col>
      </Row>

      <Row className='mt-md-5 mt-0'>
        <Col className='col-md-12'>
        <div style={{"maxWidth":"100%"}} className="related-blog homepage pb-5">

        { relatedPost.length === 0 ?  
        <> 
          <div className="single-blog">
            <div className="d-flex gap-x-30">
            <div className="blog-content"><img src={blankthumbnail} alt='' />
                <h2 className='mt-3' >    </h2> 
                <p className='mt-2'>by PubClub  -    jan 01, 2023 </p>
            </div>
            <div className="overlay-box"><img src={arrowlink} alt='' />
                <svg className="green-bg" width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.28654 0.5L38.8965 32.4416C30.1543 41.2472 15.9904 41.2395 7.26353 32.4184C-1.46334 23.5973 -1.45568 9.3056 7.28654 0.5Z" fill="#B8E819"/>
                </svg>
            </div>
            </div>
          </div>                     

            <div className="single-blog">
            <div className="d-flex gap-x-30">
            <div className="blog-content"><img src={blankthumbnail} alt='' />
                <h2 className='mt-3' >    </h2> 
                <p className='mt-2'>by PubClub  -    jan 01, 2023 </p>
            </div>
            <div className="overlay-box"><img src={arrowlink} alt='' />
                <svg className="green-bg" width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.28654 0.5L38.8965 32.4416C30.1543 41.2472 15.9904 41.2395 7.26353 32.4184C-1.46334 23.5973 -1.45568 9.3056 7.28654 0.5Z" fill="#B8E819"/>
                </svg>
            </div>
            </div>
            </div>                     
       </>
        : 
          relatedPost.map((post) => {

            return (
                    <div key={post.ID} className="single-blog">
                        <div className="d-flex gap-x-30">
                        <NavLink to={`/single/${post.ID}`}  onClick={scrollToTop}>
                        <div className="blog-content"> <img src={(post.thumbnail) ? post.thumbnail : blankthumbnail} alt='' />
                            <h2 className='mt-3' dangerouslySetInnerHTML={{__html:post.post_title}} ></h2> 
                            <p className='mt-2'>by {post.auther_name}   -    {post.post_date}</p>
                        </div>
                        </NavLink>
                        <div className="overlay-box"><img src={arrowlink} alt='' />
                            <svg className="green-bg" width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.28654 0.5L38.8965 32.4416C30.1543 41.2472 15.9904 41.2395 7.26353 32.4184C-1.46334 23.5973 -1.45568 9.3056 7.28654 0.5Z" fill="#B8E819"/>
                            </svg>
                        </div>
                        </div>
                    </div>                     
                );
            })  
          }
            </div>

        </Col>
      </Row>

    </Container>

  </section>
  {/* blog section ends */} 
  </>
  )
}

export default Homeblog
